export default class Accordion {
  constructor(selector) {
    const triggers = document.querySelectorAll(
      selector ? selector : '.accordion-trigger'
    )

    if (!triggers) return
    this.onToggle = null

    document.addEventListener('click', (e) => {
      const trigger = e.target.closest('.accordion-trigger')

      if (trigger) {
        e.preventDefault()

        trigger.classList.toggle('is-active')
        if (this.onToggle) this.onToggle.call()
      }
    })
  }
}
