import "iconify-icon";

import Accordion from "./accordion";
// import ButtonFollow from './button-follow'
// import ButtonLoadMore from './button-load-more'
import Carousel from "./carousel";

import Modals from "./modals";
// import NewsIndex from './news-index'
// import Program from './program'
import Search from "./search";
import MediaGrid from "./media-grid";
import "@/scss/base.scss";
import { FreeMode } from "swiper/modules";

class APP {
  constructor() {
    document.addEventListener("DOMContentLoaded", () => {
      console.log("[Timotion] Base online ..");

      const modals = new Modals();
      const accordion = new Accordion();
      // const buttonFollow = new ButtonFollow()
      // const buttonLoadMore = new ButtonLoadMore()
      // const program = new Program()
      // const newsIndex = new NewsIndex()
      const search = new Search();
      const media = new MediaGrid();
      this.initCarousels();
    });
  }

  initCarousels() {
    this.carousels = {
      featured: new Carousel(".swiper.is-featured", {
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
        },
      }),
      lists: new Carousel(".swiper.is-list.is-default", {
        freeMode: true,
        edgeSwipeDetection: "prevent",
        slidesPerView: 1.1,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            // pagination: false,
          },
          1080: { slidesPerView: 3.3 },
        },
      }),

      gallery: new Carousel(".swiper.is-gallery", {
        freeMode: true,
        edgeSwipeDetection: "prevent",
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-fraction",
          type: "fraction",
        },
      }),
    };
  }
}

export default new APP();
