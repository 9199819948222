import Swiper from 'swiper'

import {
  EffectFade,
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
} from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/mousewheel'

export default class Carousel {
  constructor(selector, options = {}) {
    this.instance = new Swiper(selector, {
      modules: [
        //EffectFade,
        Navigation,
        Pagination,
        Mousewheel,
        FreeMode,
      ],
      mousewheel: {
        forceToAxis: true,
      },
      navigation: {
        nextEl: '.swiper-button.is-next',
        prevEl: '.swiper-button.is-prev',
      },
      ...options,
    })
  }
}
