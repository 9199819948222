// import '@/scss/components/media-grid.scss'

export default class MediaGrid {
  constructor() {
    const media = document.querySelector("#media-grid");
    if (media == null) return;

    const total = media.querySelectorAll(".image").length;

    if (!media) return;

    const imageLinkTemplate = ({ path, url, title }) =>
      url != null
        ? `<a target="_blank" href="${url}"><img class="is-transparent is-cover" src="${path}" loading="lazy" alt="${title}"  /></a>`
        : `<img class="is-transparent is-cover" loading="lazy" src="${path}" alt="${title}" title="${title}"  />`;
    const random = (min, max) =>
      Math.floor(Math.random() * (max - min + 1)) + min;

    const boxes = media.querySelectorAll(".image");
    let images = document.querySelector("#media-images");

    if (!images) return;

    images = JSON.parse(images.textContent);

    const swapImages = (container) => {
      const img = container.querySelectorAll("img.is-transparent");
      const active = container.querySelector("img:not(.is-transparent)");

      if (active != null) active.classList.add("is-transparent");
      try {
        setTimeout(
          () => {
            const rindex = random(0, img.length - 1);
            if (img[rindex]) img[rindex].classList.remove("is-transparent");
          },
          active ? 500 : 0
        );
      } catch {}
    };

    for (let i = 0; i < images.length; i++) {
      // const index = random(0, boxes.length - 1)
      const index = i % total;
      console.log(images[i]);
      boxes[index].innerHTML += imageLinkTemplate(images[i]);
    }

    for (const box of boxes) {
      if (box.children.length > 1) {
        setInterval(() => {
          requestAnimationFrame(() => {
            swapImages(box);
          });
        }, random(4000, 8000));
      }

      swapImages(box);
    }
  }
}
